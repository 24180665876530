<template>
  <div :class="imageSrc === fallbackSrc ? 'h-7' : 'h-full'">
    <img
      class="w-full h-full object-contain"
      loading="lazy"
      decoding="async"
      :src="imageSrc"
      @error="onError"
    />
  </div>
</template>

<script lang="ts" setup>
import type { AttachmentMeta } from '#attachment/types'

const props = defineProps<{
  meta: AttachmentMeta
}>()

const fallbackSrc = computed(() => {
  return `https://drive-thirdparty.googleusercontent.com/64/type/${props.meta.mime_type}`
})

const imageSrc = computed(() => {
  if (props.meta.thumbnail_url) {
    return props.meta.thumbnail_url
  }

  return fallbackSrc.value
})

const onError = (event: Event) => {
  const target = event.target as HTMLImageElement
  target.src = fallbackSrc.value
}
</script>
